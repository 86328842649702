<div *ngIf="!isEmptyData && !disableddownloadbtn">
    <div class="pageLoads">
        <div class="loader"></div><br>
        <span class="LoadText">Loading... Please Wait...</span>
    </div>
</div>

<div class="nodata" *ngIf="isEmptyData">

    <h4><i class="fa fa-exclamation-triangle" aria-hidden="true"></i><br> No Data Found For This Link</h4>
</div>

<button *ngIf="disableddownloadbtn" type="button" class="download" (click)="Download()"><i class="fa fa-download"
        aria-hidden="true"></i>
</button>

<pdf-viewer *ngIf="disableddownloadbtn" [external-link-target]="'blank'" [src]="ViewFileSrc" [render-text]="true"
    style="display:block;width:100%">
</pdf-viewer>