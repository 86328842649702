import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SrvlabdetailsService } from '../Services/srvlabdetails.service';

@Component({
  selector: 'app-view-lab-result',
  templateUrl: './view-lab-result.component.html',
  styleUrls: ['./view-lab-result.component.scss']
})
export class ViewLabResultComponent implements OnInit {
  UniqueKey: number;
  ViewFileSrc:any
  binarydata:any
  anchor: HTMLAnchorElement;
  disableddownloadbtn: boolean;
  isEmptyData: boolean = false;
  constructor(private objsrvlab:SrvlabdetailsService,
    private activeRoute: ActivatedRoute,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(routeParams => {
      this.UniqueKey = routeParams.id;
    });
    this.GetDetails()
  }

  GetDetails(){
    debugger
    this.objsrvlab.GetFilesbyuniquekey(this.UniqueKey,'').subscribe(data=>{
      if(data.status){
        var resdata=data.ResultData;
        if(resdata.Table.length>0){
          this.isEmptyData = false;
          this.disableddownloadbtn=true
          var datalist=resdata.Table[0];
          this.binarydata =datalist.LabreportData;
          const byteCharacters = atob(this.binarydata);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(blob);
          this.ViewFileSrc=fileURL
        }
        else{
          this.isEmptyData = true;
          this.ViewFileSrc=[]
          this.disableddownloadbtn=false
        }
        
       // window.open(fileURL); 
      }
      else{
        this.isEmptyData = true;
        this.toastr.error(data.ValidationMessage);
      }
    })
  }

  Download(){
        const byteCharacters = atob(this.binarydata);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/pdf'});
       // var fileURL = URL.createObjectURL(blob);
        this.anchor = document.createElement('a');
        this.anchor.download = "Labresult";
        // Use the URL object to create a temporary URL
        this.anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        this.anchor.click();
   
  }

  // close(){
  //   window.close()
  // }

}
