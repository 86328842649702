import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SrvDashboardService } from '../Services/srv-dashboard.service';
import { SrvlabdetailsService } from '../Services/srvlabdetails.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lab-details',
  templateUrl: './lab-details.component.html',
  styleUrls: ['./lab-details.component.scss']
})
export class LabDetailsComponent implements OnInit {
  RequestForm:FormGroup;
  DataList: any;
  hasValues:boolean=false;
  status3: boolean = true;
  constructor(private toastr: ToastrService,public objsrvdash:SrvDashboardService,private router: Router,private FrmBuilder: FormBuilder,private srvlabservice:SrvlabdetailsService) { }

  ExpandTab(){
    this.status3 = !this.status3; 
  }
  ngOnInit(): void {
    var userdata=JSON.parse(localStorage.getItem("Loggedinuser"));
    if(userdata == null || userdata ==""){
      this.router.navigateByUrl('/login');
    }
    this.initialize();
  }
  initialize(){
    this.RequestForm=this.FrmBuilder.group({
      fromdate:[,],
      todate:[,],
      labtestname:[,'']
    })
  }
  SearchData(){
    this.hasValues=false;
    this.srvlabservice.GetFilteredData(this.RequestForm.value).subscribe(data=>{
      if(data.status){
        var resdata=data.ResultData;
        this.DataList=resdata.Table;
        if(this.DataList.length==0){
          this.hasValues=true;
        }
      }
    })
  }
  ShowLabResult(list){
    this.objsrvdash.GetFileDetails(list).subscribe(data=>{
      if(data.status){
        var resdata=data.ResultData;
        var datalist=resdata.Table[0];
        var binarydata =datalist.LabreportData;
        const byteCharacters = atob(binarydata);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(blob);
       // this.GetDashbardData();
        window.open(fileURL); 
      }
      else{
        this.toastr.error(data.ValidationMessage,'LabResultViewer');
      }
    })
  }
  Logout(){
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

}
