import { Injectable } from '@angular/core';
import { Constants } from '../config/webconfig';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private baseUrl: string = Constants.Configuration.apiServiceBaseUri;
  constructor(private http: HttpClient) { }
  
  postCommonApiUrl(apiFn: string, body?: any, paramList?: HttpParams) {

    return this.http.post<any>(this.baseUrl +  apiFn, body, { params: paramList });//, { params: paramList });
  }

  postCommonApi(apiFn: string, body?: any, paramList?: HttpParams) {

    return this.http.post<any>(this.baseUrl +  apiFn, body);//, { params: paramList });
  }
}
