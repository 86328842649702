export const config: any = {

    pageSize: 10,
    dateFormat: 'dd-MM-yyyy',
    //apiServiceBaseUri: 'http://afiqdesvm06.eastus2.cloudapp.azure.com:88/API/'
    
    //apiServiceBaseUri: 'http://localhost:6087/API/'
    //apiServiceBaseUri: 'https://gshoman.qdhealth.net:493/GSHAPI/API/'
    //apiServiceBaseUri:'http://qdtestserver01.eastus2.cloudapp.azure.com:116/LISAPI/API/'
   apiServiceBaseUri:'http://qdtestserver01.eastus2.cloudapp.azure.com:116/LISAPI/API/'
    //http://afiqdesvm06.eastus2.cloudapp.azure.com:86/API/
    //apiServiceBaseUri: 'http://afisg002.eastus.cloudapp.azure.com:88/GSHAPI/API/'
                        
 
   };
   export class Constants 
   {
    static Configuration = class
    {
      //static apiServiceBaseUri:string ='http://localhost:6087/API/';
       //static apiServiceBaseUri:string ='http://afiqdesvm06.eastus2.cloudapp.azure.com:88/API/';
       //static apiServiceBaseUri:string = 'https://gshoman.qdhealth.net:493/GSHAPI/API/';
      // static apiServiceBaseUri:string = 'http://qdtestserver01.eastus2.cloudapp.azure.com:116/LISAPI/API/';
      static apiServiceBaseUri:string = 'http://qdtestserver01.eastus2.cloudapp.azure.com:116/LISAPI/API/';
     
    };
  
  }