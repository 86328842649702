import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LabDetailsComponent } from './lab-details/lab-details.component';
import { LoginComponent } from './login/login.component';
import { ViewLabResultComponent } from './view-lab-result/view-lab-result.component';
import { ViewResultComponent } from './view-result/view-result.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: 'login', component: LoginComponent },
   { path: 'login', component: LoginComponent },
   { path: 'dashboard', component: DashboardComponent },
   { path: 'labdetails', component: LabDetailsComponent },
   { path: 'ViewResult', component: ViewResultComponent },
   { path: 'view-lab-result/:id', component: ViewLabResultComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
