import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor{
    intercept(request:HttpRequest<any>,next:HttpHandler){
         
        var token=localStorage.getItem("Token");
        if(token!=null){
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }
        
        if (!request.headers.has('Content-Type')) {
            request = request.clone({
              headers: request.headers.set('Content-Type', 'application/json')
            });
          }
      
          request = request.clone({
            headers: request.headers.set('Access-Control-Allow-Origin', '*')
          });
          request = request.clone({
            headers: request.headers.set('Access-Control-Allow-Headers', '*')
          });
          request = request.clone({
            headers: request.headers.set('Access-Control-Allow-Methods', '*')
          });
      
          request = request.clone({
            headers: request.headers.set('Accept', 'application/json')
          });
    
        return next.handle(request)
    }
}