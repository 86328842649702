<nav class="navbar navbar-dark bg-dark d-flex justify-content-between" style="background:#464775 !important;     padding: 0 15px;">

<a class="navbar-brand"><img src="assets/img/logo.png" style="height: 40px;"/> <span class="user">&nbsp; - <i class="fa fa-user-circle-o" aria-hidden="true"></i> {{userdata[0].Usr_Name}}</span></a>
   
<li class="dropdown nav-item d-block d-sm-none text-white" (click)="Logout()" >
    <a >Logout &nbsp;<i class="fa fa-sign-out" aria-hidden="true"></i></a>
</li>

<ul class="text-white ul-sm d-none d-sm-flex">

    <li class="count1"><a> <i class="fa fa-flask" aria-hidden="true"></i> Total lab results data <span> {{totallabresult}}</span></a></li>
<li class="count2"><a><i class="fa fa-flag-o" aria-hidden="true"></i> Unread lab reports <span> {{totalunreadresult}}</span></a></li>
    <li class="dropdown nav-item d-none d-sm-flex" (click)="Logout()" >
        <a >Logout &nbsp;<i class="fa fa-sign-out" aria-hidden="true"></i></a>
   
    </li>
</ul>
     
  
  </nav>
  <ul class="text-white ul-sm  d-block d-sm-none">

    <li class="count1"><a> <i class="fa fa-flask" aria-hidden="true"></i> Total lab results data <span> {{totallabresult}}</span></a></li>
<li class="count2"><a><i class="fa fa-flag-o" aria-hidden="true"></i> Unread lab reports <span > {{totalunreadresult}}</span></a></li>
    <li class="dropdown nav-item d-none d-sm-flex" (click)="Logout()" >
        <a >Logout &nbsp;<i class="fa fa-sign-out" aria-hidden="true"></i></a>
   
    </li>
</ul>

