import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { SrvloginService } from '../Services/srvlogin.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import Stepper from 'bs-stepper';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('slideDownUp', [
      transition(':enter', [style({ height: 0 }), animate(500)]),
      transition(':leave', [animate(500, style({ height: 0 }))]),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  RequestForm:FormGroup;
  submited:boolean=false;
  Pwd_Section:boolean=false;
  Is_pwdgenerated:boolean=false;
  frmfinalsubmit:boolean=false;
  ClientKey:any;
  UserData:any;
  ShowHospitaldropdown:boolean=false;
  private stepper: Stepper;
  OTPUsr_Mobilenumber:any='';
  OTPUsr_PatientID:any='';
  btnsendotp:boolean=false;
  OTP_Number:any;
  Is_OTPverfied:boolean=false;
  CnfmNewPassword:any='';
  NewPassword:any='';
  CountryCode:any='968';

  constructor(private objlogin:SrvloginService,private FrmBuilder: FormBuilder,private toastr: ToastrService,private router: Router) { }
  ngOnInit(): void {
    this.ClearLocaldata();
    this.initialize();
    this.steper();
  }
  steper(){
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    });
  }
  onSubmit() {
    return false;
  }
  SendOTP() {
    this.btnsendotp=true;
    if(this.OTPUsr_Mobilenumber!='' && this.OTPUsr_PatientID!=''){
      var hospname=this.RequestForm.controls['HospitalName'].value;
      this.objlogin.SendOTP(this.OTPUsr_Mobilenumber,this.OTPUsr_PatientID,hospname).subscribe(data=>{
        if(data.status){
          this.toastr.success("OTP has been send to the mobile number");
          this.Is_OTPverfied=false;
          this.stepper.next();
        }
        else{
          this.toastr.error(data.ValidationMessage)
        }
      })
    }
    else{
      return ;
    }
  }
  ClearLocaldata()
  {
    localStorage.clear();
  }
  initialize(){
    this.RequestForm=this.FrmBuilder.group({
      CountryCode:['968',Validators.required],
      Loginphone_usrid:[,Validators.required],
      Loginpwd:[,Validators.required],
      Loginpatid:[,''],
      HospitalName:[,'']
    })
  }
  ResetForm(){
    this.RequestForm.reset();
  }
  OnLoginClick(){
    this.frmfinalsubmit=true;
    if(this.RequestForm.valid){
      if(this.Is_pwdgenerated){
        this.objlogin.UserAuthentication(this.RequestForm.value).subscribe(arg => {
          if(!arg.status){
            this.toastr.error('Invalid User Credentials','LabResultViewer');
          }
          else{
            localStorage.setItem("Loggedinuser",JSON.stringify(arg.ResultData));
            localStorage.setItem("Token",arg.AuthorizationToken);
            this.router.navigateByUrl('/dashboard');
          }
        });
      } 
      else{
        this.objlogin.Createuserpassword(this.RequestForm.value).subscribe(arg => {
          if(!arg.status){
            this.toastr.error('Invalid User Credentials','LabResultViewer');
          }
          else{
            localStorage.setItem("Loggedinuser",JSON.stringify(arg.ResultData));
            localStorage.setItem("Token",arg.AuthorizationToken);
            this.router.navigateByUrl('/dashboard');
          }
        });
      }  
    }
    // else{
    //   this.toastr.warning('Please enter username and password','LabResultViewer');
    // }
  }
  onChange(data){
    this.UserData.filter(x=>{
      if(x.ClientKey==data){
        if(x.Is_Pwdgenerated=="1")
          this.Is_pwdgenerated=true;
        else
          this.Is_pwdgenerated=false;
      }
    })
    //console.log(data);
    this.RequestForm.controls['HospitalName'].setValue(data);
  }
  VerifyOTP(){
      if(this.OTP_Number!='' && this.OTP_Number!=null){
        var hospname=this.RequestForm.controls['HospitalName'].value;
        this.objlogin.VerifyOTP(this.OTPUsr_Mobilenumber,this.OTPUsr_PatientID,hospname,this.OTP_Number).subscribe(data=>{
          if(data.status){
            this.Is_OTPverfied=true;
            this.stepper.next();
          }
          else{
            this.toastr.error(data.ValidationMessage);
          }
        })
      }
      else{
        this.toastr.warning("Please Entert OTP");
      }
  }

  
  VerifyUser(){
    this.submited=true;
    if(this.RequestForm.value.Loginphone_usrid!= null && this.RequestForm.value.Loginphone_usrid!='' &&
    this.RequestForm.value.Loginpatid!=null && this.RequestForm.value.Loginpatid!='' ){
     // this.RequestForm.patchValue({Loginphone_usrid:this.CountryCode+this.RequestForm.controls['Loginphone_usrid'].value});
    
      this.objlogin.UserAuthentication(this.RequestForm.value).subscribe(arg => {
        if(!arg.status){
          this.toastr.error('User Not Found','LabResultViewer');
        }
        else{
          this.UserData=arg.ResultData;
          if(this.UserData.length>1){
              this.ShowHospitaldropdown=true;
              this.Pwd_Section=true;
          }
          else if(this.UserData.length==1){
           // this.RequestForm.setValue()
            this.RequestForm.controls['HospitalName'].setValue(this.UserData[0].ClientKey);
            if(arg.ResultData[0].Is_Pwdgenerated=="1")
              this.Is_pwdgenerated=true;
            else
              this.Is_pwdgenerated=false;
              this.Pwd_Section=true;
              //Code added to avoid the password authentication in UI
            localStorage.setItem("Loggedinuser",JSON.stringify(arg.ResultData));
            localStorage.setItem("Token",arg.AuthorizationToken);
            this.router.navigateByUrl('/dashboard');
          }         
        }
      });  
    }
 
  }
  OnForgotPwdClick(){
  
  }
  closemodal(){
    //document.getElementById("myModal").hidden;
    //document.querySelector('#myModal').modal("hide");
   // $("#myModal").modal("hide");
  }
  ClearOTPData(){
    this.OTPUsr_Mobilenumber='';
    this.OTPUsr_PatientID='';
    this.NewPassword='';
    this.CnfmNewPassword='';
    this.OTP_Number='';
    this.Is_OTPverfied=false;
    this.Pwd_Section=false;
    this.closebutton.nativeElement.click();
    this.ResetForm();
  }
  UpdateUserPassword(){
    if(this.NewPassword!='' && this.NewPassword!=null && this.CnfmNewPassword!='' && this.CnfmNewPassword!=null){
      if(this.NewPassword==this.CnfmNewPassword){
        var hospname=this.RequestForm.controls['HospitalName'].value;
        this.objlogin.UpdateuserPassword(this.OTPUsr_Mobilenumber,this.CnfmNewPassword,this.OTPUsr_PatientID,hospname).subscribe(data=>{
          if(data.status){
            this.toastr.success("Password updated successfully");
            this.ClearOTPData();
            this.router.navigateByUrl('/login');
          }
          else{
            this.toastr.error(data.ValidationMessage);
          }
        })
      }
      else{
          this.toastr.warning("Password Mismatch");
      }
    }
  }
}
