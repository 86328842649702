



<div class="container mt-3">
    <div class="card"  [ngClass]="status3 ? 'SmallDiv' : 'ExpandDiv'">

        <div class="d-flex justify-content-between">
            <div> <p>Advance Search</p></div>
          
            <div> <button type="button" (click)="ExpandTab()" class="btn btn-Card" title="maximize"> <i class="fa fa-window-maximize" aria-hidden="true" [ngClass]="status3 ? 'fa-window-maximize' : 'fa-window-minimize'"></i></button></div>
           
          </div>
       
    <form class="RequestForm"  [formGroup]="RequestForm" autocomplete="off"> 
        <div class="row top-row mb-2">
           
    <div class="col-md-1 col-sm-4 col-4 pr-0"><label>From Date</label></div>
    <div class="col-md-2 col-sm-8 col-8 mb-1">  <input type="date" class="form-control" formControlName="fromdate"/></div>
    
    <div class="col-md-1 col-sm-4 col-4 pr-0"><label>To Date</label></div>
    <div class="col-md-2 col-sm-8 col-8 mb-1">
        <input type="date" class="form-control" formControlName="todate"/>
    </div>
    <div class="col-md-1 col-sm-4 col-4 p_0"><label>Lab test name</label></div>
    <div class="col-md-3 col-sm-8 col-8 mb-1">
        <input type="text" class="form-control" formControlName="labtestname"/>
    </div>
    <div class="col-md-1 col-sm-12 mb-1 p_0">
        <button type="button" class="btn btn-warning btn-sm float-right-1" (click)="SearchData()"><i class="fa fa-search" aria-hidden="true"></i>
             Search</button>
    </div>
</div>
    </form>

    <div class="row">
        <div class="col-md-12">
            <div class="table-fixed">
            <table class="table table-bordered table-striped bg-white">
                <thead>
                    <tr>
                      <th scope="col" style="width: 50px;">#</th>
                      <th scope="col" style="width: 112px;">Created Date</th>
                      <!-- <th scope="col">Hospital Name</th> -->
                      <th scope="col" style="width: 140px;">Bill No</th>
                      <th scope="col">Test Name</th>
                      <th scope="col" style="width: 82px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="hasValues">
                        <td colspan="5" class="text-center">No Records</td>
                    </tr>
                    <tr  *ngFor="let item of DataList let i=index" >
                        <td>{{i+1}}</td>
                        <td>{{item.Created_Date | date }} </td>
                        <!-- <td>{{item.HospitalName}}</td> -->
                        <td>{{item.Bill_No}}</td>
                        <td>{{item.Test_Name}}</td>
                        <td style="width:72px">
                            <button type="button" class="btn btn-primary btn-sm" (click)="ShowLabResult(item)"><i class="fa fa-eye" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
    </div>
    
</div>

</div>

