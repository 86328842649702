import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LabDetailsComponent } from './lab-details/lab-details.component';
import { AuthHeaderInterceptor } from './http-interceptors/auth-header-interceptor';
import { FooterComponent } from './footer/footer.component';
import { NavabrComponent } from './navabr/navabr.component';
import { ViewResultComponent } from './view-result/view-result.component';
import { ViewLabResultComponent } from './view-lab-result/view-lab-result.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    LabDetailsComponent,
    FooterComponent,
    NavabrComponent,
    ViewResultComponent,
    ViewLabResultComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ToastrModule.forRoot(),
    PdfViewerModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHeaderInterceptor,
    multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
