import { Injectable } from '@angular/core';
import { CommonService } from './CommonService';
@Injectable({
  providedIn: 'root'
})
export class SrvlabdetailsService {

  constructor(public comm:CommonService) { }
  Createbody(payload){
    var userdata=JSON.parse(localStorage.getItem("Loggedinuser"));
    var body={
      LoggedInInfo:userdata[0],
      Payload:payload
    }
    return body;
  }

  GetFilteredData(payload){
    var data={
      fromdate:payload.fromdate,
      todate:payload.todate,
      labtestname:payload.labtestname
    }
    return  this.comm.postCommonApi("Labdetails/GetLabresultData",this.Createbody(data))
  }
  GetDashboardDetails(){
    var userdata=JSON.parse(localStorage.getItem("Loggedinuser"));
    var body={
      LoggedInInfo:userdata[0]
    }
    return  this.comm.postCommonApi("LabresultDashboard/GetLabresultData",body)
  }
  GetFilesbyuniquekey(uniquekey,clientkey){
    var body={
      "UniqueKey":uniquekey,
      "ClientKey":clientkey
    }
    return  this.comm.postCommonApi("Labdetails/GetFilesbyuniquekey",body)
  }
}
