import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SrvlabdetailsService } from '../Services/srvlabdetails.service';

@Component({
  selector: 'app-view-result',
  templateUrl: './view-result.component.html',
  styleUrls: ['./view-result.component.scss']
})
export class ViewResultComponent implements OnInit {
  UniqueKey:any='';
  ClientKey:any='';
  constructor(private toastr: ToastrService,private activeRoute: ActivatedRoute,private objsrvlab:SrvlabdetailsService) { }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(
      params => {
        this.UniqueKey =  params['UniqueKey'];
        this.ClientKey=params['ClientKey'];
        if(this.UniqueKey!=null && this.UniqueKey!='' && this.ClientKey!=null && this.ClientKey!=''){
            this.GetDetails();
        }
      } 
    )
  }
  GetDetails(){
    this.objsrvlab.GetFilesbyuniquekey(this.UniqueKey,this.ClientKey).subscribe(data=>{
      if(data.status){
        var resdata=data.ResultData;
        var datalist=resdata.Table[0];
        var binarydata =datalist.LabreportData;
        const byteCharacters = atob(binarydata);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL,"_self"); 
      }
      else{
        this.toastr.error(data.ValidationMessage,'LabResultViewer');
      }
    })
  }

}
