
<app-navabr [models]="TreeData"></app-navabr>
<section>
<div class="container ">
  
	<div class="row">
	
    </div>
    <div class="row"> 
        <div class="col-md-6  mt-3"  [ngClass]="status ? '' : 'closeDiv'">
            <div class="card">

                <div class="d-flex justify-content-between ">
                    <div >
                        <p>Recent Lab Result</p>
                    </div>
                    <div > <button type="button" (click)="Close_tab()" class="btn btn-Card"><i class="fa fa-times" aria-hidden="true"></i></button></div>
                   
                  </div>
        
           
<div class="fix-tab-1">
            <table class="table table-striped mb-0">
                <thead>
                  <tr>
                    <th scope="col" style="width: 42px;">Sl No</th>
                    <th scope="col" style="width: 80px;">Bill Date</th>
                    <th scope="col">Bill No</th>
                    <th scope="col">Test Name</th>
                    <th scope="col" colspan="3">Action</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngIf="Listtopten==''">
                        <td colspan="5" class="text-center">No Records</td>
                    </tr>
                    <tr *ngFor="let item of Listtopten let i=index" >
                        <td>{{i+1}}</td>
                        <td>{{item.Created_Date | date }} </td>
                        <td>{{item.Bill_No}}</td>
                        <td>{{item.Test_Name}}</td>
                        <td style="width: 128px;" class="actions">
                            
                            <button type="button" class="btn btn-warning btn-sm ml-1 mr-1" (click)="ShowLabResult(item)"><i class="fa fa-eye" aria-hidden="true"></i></button>
                      
                            <button type="button" class="btn btn-primary btn-sm ml-1 mr-1" (click)="Print(item)"><i class="fa fa-print" aria-hidden="true"></i></button>
                       
                            <button type="button" class="btn btn-secondary btn-sm ml-1 mr-1" (click)="Download(item)"><i class="fa fa-download" aria-hidden="true"></i> </button>
                        </td>
                    </tr>
                </tbody>
              </table>
            </div>
            </div>
        </div>  
        <div class="col-md-6 mt-3" [ngClass]="status2 ? '' : 'closeDiv'">
            <div class="card">
           

            
            <div class="d-flex justify-content-between ">
                <div> <p>Recent unread lab reports</p></div>
                <div> <button type="button" (click)="Close_tab2()" class="btn btn-Card"><i class="fa fa-times" aria-hidden="true"></i></button></div>
               
              </div>
    
              <div class="fix-tab-1">
            <table class="table table-striped ">
                <thead>
                    <tr>
                      <th scope="col">Sl No</th>
                      <th scope="col">Bill Date</th>
                      <th scope="col">Bill No</th>
                      <th scope="col">Test Name</th>
                      <th scope="col" colspan="3">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="ListUnread==''">  <td colspan="5" class="text-center">No Records</td></tr>
                    <tr  *ngFor="let item of ListUnread let i=index" >
                        <td>{{i+1}}</td>
                        <td>{{item.Created_Date | date }} </td>
                        <td>{{item.Bill_No}}</td>
                        <td>{{item.Test_Name}}</td>
                        <td style="width: 128px;" class="actions">
                            <button type="button" class="btn btn-warning btn-sm mr-1" (click)="ShowLabResult(item)"><i class="fa fa-eye" aria-hidden="true"></i></button>
                        
                            <button type="button" class="btn btn-primary  btn-sm mr-1" (click)="Print(item)"><i class="fa fa-print" aria-hidden="true"></i></button>
                      
                            <button type="button" class="btn btn-secondary  btn-sm mr-1" (click)="Download(item)"><i class="fa fa-download" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
        </div>  
    </div>
</div>
<app-lab-details></app-lab-details>
</section>
<app-footer></app-footer>