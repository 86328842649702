<!-- <div class="container">
    <form class="RequestForm"  [formGroup]="RequestForm">
        <label>Username</label>
        <input type="text" formControlName="Loginphone_usrid" class="" />
        <br>
        <label>Patient ID</label>
        <input type="text" formControlName="Loginpatid" />
        <br>
        <label>Password</label>
        <input type="password" formControlName="Loginpwd"/>
        <button type="button" class="btn btn-primary" (click)="OnLoginClick()">Login</button>

        
    </form>
</div> -->

<section class="d-flex align-items-center bd-highlight h-100">
<div class="container">
    <form class="RequestForm"  [formGroup]="RequestForm" autocomplete="off">
     
    <div class="row mx-399">
      <div class="col-sm-12 mx-auto">
        <!-- <h4 class="text-center" style="margin-bottom: -53px;">Gulf Specialized Hospital</h4> -->
        <div class="img-lab">
          <img src="assets/img/flask.png">
        </div>
        <div class="card card-signin my-5">
       
          <div class="card-body">

            <h5 class="card-title text-center mb-4 mt-5">Sign In</h5>
            <div class="form-label-group row mb-2"  *ngIf="!Pwd_Section">
              <div class="col-12">
              <table class="w-100">
                <tr>
                  <td style="width: 100px;">Phone</td>
                  <td>
                    
                    <table class="phone">
                      <tr>
                        <td width="55 ">  <select class="form-control" formControlName="CountryCode" disabled>
                          <option selected value="968">968</option>
                      </select></td>
                      <td>
                        <input type="text"  class="form-control " placeholder="Mobile" formControlName="Loginphone_usrid" required autofocus>
                        <div *ngIf="submited && RequestForm.controls.Loginphone_usrid.errors" class="error-main">
                          <div *ngIf="RequestForm.controls.Loginphone_usrid.errors.required"  class="validationcolor">Phone number Required</div>
                      </div>
                      </td>
                      </tr>
                    </table>
                  
                   
                  </td>
                </tr>
                <tr *ngIf="!Pwd_Section">
                  <td>File Number</td>
                  <td>
                    <input type="text"  class="form-control" placeholder="File Number" formControlName="Loginpatid" required autofocus>
            
                    <div *ngIf="submited && RequestForm.controls.Loginpatid.errors" class="error-main">
                      <div *ngIf="RequestForm.controls.Loginpatid.errors.required"  class="validationcolor">File Number Required</div>
                  </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td><button type="button" class="btn btn-primary mt-2 btn-sm" (click)="VerifyUser()">LOGIN <i class="fa fa-angle-right" aria-hidden="true"></i></button></td>
                </tr>
              </table>
            </div>
              <!-- <label class="col-3  pr-0">Phone</label>
              <div class="col-9">
              <input type="text"  class="form-control " placeholder="Mobile" formControlName="Loginphone_usrid" required autofocus>
        
              <div *ngIf="submited && RequestForm.controls.Loginphone_usrid.errors" class="error-main">
                  <div *ngIf="RequestForm.controls.Loginphone_usrid.errors.required"  class="validationcolor">Phone number Required</div>
              </div>
            </div> -->
            </div>

            <!-- <div class="form-label-group row" *ngIf="!Pwd_Section">
              <label class="col-3 pr-0">File Number</label>
              <div class=" col-9">
              <input type="text"  class="form-control" placeholder="Patientid" formControlName="Loginpatid" required autofocus>
            
              <div *ngIf="submited && RequestForm.controls.Loginpatid.errors" class="error-main">
                <div *ngIf="RequestForm.controls.Loginpatid.errors.required"  class="validationcolor">File Number Required</div>
            </div>
            <button type="button" class="btn btn-primary mt-2 btn-sm" (click)="VerifyUser()">NEXT <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
        
            </div> -->

        
            <!-- *ngIf="Pwd_Section" -->
            <div class="form-label-group row" @slideDownUp *ngIf="Pwd_Section">
              <div class="row w-100 m-0 mb-2" *ngIf="ShowHospitaldropdown">
                <div class="col-12">
                <table class="w-100">
                  <tr>
                    <td>Hospital</td>
                    <td><select class="form-control"  (change)="onChange($event.target.value)">
                      <option value=""></option>
                        <option  *ngFor="let item of UserData let i=index" value={{item.ClientKey}}>{{item.ClientName}}</option>
                    </select></td>
                  </tr>
                </table>
              </div>
                <!-- <label for="inputPassword" class="col-3">Hospital</label>
                <div class="col-9">
                <div >
                  <select class="form-control"  (change)="onChange($event.target.value)">
                    <option value=""></option>
                      <option  *ngFor="let item of UserData let i=index" value={{item.ClientKey}}>{{item.ClientName}}</option>
                  </select>
                </div>
                </div> -->
              </div>
              <div class="row w-100 m-0">
              <!-- <label *ngIf="Is_pwdgenerated" for="inputPassword" class="col-3 pr-0"></label>
              <label *ngIf="!Is_pwdgenerated" for="inputPassword" class="col-3 pr-0">Create Password</label> -->
                <div class="col-12">
              <table class="w-100">
                <tr>
                  <td *ngIf="Is_pwdgenerated" >Password</td>
                  <td *ngIf="!Is_pwdgenerated" style="width: 133px;">Create Password</td>
                  <td >
                    <input type="password" id="inputPassword" class="form-control" placeholder="Password"  formControlName="Loginpwd" required>
            

              <div *ngIf="frmfinalsubmit && submited && RequestForm.controls.Loginpwd.errors" class="error-main">
                  <div *ngIf="RequestForm.controls.Loginpwd.errors.required"  class="validationcolor">Password Required</div>
              </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td> 
                    <button  class="btn btn-primary mt-2 btn-sm" type="button" (click)="OnLoginClick()">SIGN IN <i class="fa fa-sign-in" aria-hidden="true"></i></button>
                   
                  </td>
                </tr>
                <tr>
                  <td></td>
              
                    <td> <button data-backdrop="static" data-keyboard="false" class="btn bw-white btn-sm p-0 mt-2 text-primary" type="button" (click)="OnForgotPwdClick()"  data-toggle="modal" data-target="#myModal">Forgot Password <i class="fa fa-lock" aria-hidden="true"></i></button>
                  </td>
                </tr>
              </table>
            </div>
              <!-- <div class="col-9">
              <input type="password" id="inputPassword" class="form-control" placeholder="Password"  formControlName="Loginpwd" required>
            

              <div *ngIf="frmfinalsubmit && submited && RequestForm.controls.Loginpwd.errors" class="error-main">
                  <div *ngIf="RequestForm.controls.Loginpwd.errors.required"  class="validationcolor">Password Required</div>
              </div>

             <div>
               <button class="btn btn-primary" type="button" (click)="OnForgotPwdClick()">Forgot Password</button>
             </div>
              <button  class="btn btn-primary mt-2 btn-sm" type="button" (click)="OnLoginClick()">SIGN IN <i class="fa fa-sign-in" aria-hidden="true"></i></button>
            </div> -->
          </div>
            </div>
         
    
          </div>
        </div>
      </div>
    </div>
    <!-- <p class="text-center" style="margin-top: -15px;font-size: 13px;">Note : Please enter the registered Mobile number and the File number as recorded on the invoice.</p> -->

   
</form>
  </div>
  
</section>
<div class="contact">
 
  <p class="text-center mb-2 mt-1" style="margin-top: -15px;font-size: 13px;color:#484545f2;">
    ورقم الملف كما هو مسجل في الفاتورة(968xxxxxxxx مثال) الرجاء إدخال رقم الهاتف المحمول المسجل مع رمز البلد  
   <br> Note : Please enter the registered mobile with country code (Example for Oman 968XXXXXXXX) and file number as recorded on the invoice .</p>
  <!-- <i class="fa fa-info-circle" aria-hidden="true"></i>
  For assistance, please call<b> 22081700</b> , Gulf specialized Hospital   -->
</div>


<div class="container">

  <!-- Button to Open the Modal -->


  <!-- The Modal -->
  <div class="modal" id="myModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
      
        <button type="button" #closebutton class="close text-right p-2" data-dismiss="modal" >&times;</button>
        <div class="modal-body">
          <div class="container">

            <div id="stepper1" class="bs-stepper">
              <fieldset disabled>
              <div class="bs-stepper-header">
                <div class="step" data-target="#test-l-1">
                  <button class="step-trigger">
                    <span class="bs-stepper-circle">1</span>
                  </button>
                </div>
                <div class="line"></div>
                <div class="step" data-target="#test-l-2">
                  <button class="step-trigger">
                    <span class="bs-stepper-circle">2</span>
                  </button>
                </div>
                <div class="line"></div>
                <div class="step" data-target="#test-l-3">
                  <button class="step-trigger">
                    <span class="bs-stepper-circle">3</span>
                  </button>
                </div>
              </div>
            </fieldset>
              <div class="bs-stepper-content">
                  <div id="test-l-1" class="content">
                    <div class="form-group">
                      <table class="w-100 table-inline">
                        <tr>
                          <td style="width: 109px;">  Phone Number</td>
                          <td>    
                            <input type="text"  [(ngModel)]="OTPUsr_Mobilenumber" class="form-control"   placeholder="Enter Number" />
                            <div *ngIf="btnsendotp &&  OTPUsr_Mobilenumber==''" class="error-main">
                              <div   class="validationcolor">Mobile Number Required</div>
                          </div>
                          </td>
                        </tr>
                        <tr>
                          <td>File Number</td>
                          <td> 
                            <input type="text" [(ngModel)]="OTPUsr_PatientID"  class="form-control"  placeholder="Enter File No" />
                            <div *ngIf="btnsendotp &&  OTPUsr_PatientID==''" class="error-main">
                              <div   class="validationcolor">Patient File Number Required</div>
                          </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>  <button (click)="SendOTP()" class="btn btn-primary">Send OTP</button></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div id="test-l-2" class="content">
                    <div class="form-group">
                      <table class="w-100 table-inline">
                        <tr>
                          <td style="width:40px">OTP</td>
                          <td class="relative"> 
                            <input type="text" [(ngModel)]="OTP_Number"  class="form-control"  placeholder="Enter OTP" />

                            <i class="fa otp" [ngClass]="(Is_OTPverfied)?'fa-check':'fa-times'" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td> <button (click)="VerifyOTP()" class="btn btn-primary">Verify OTP</button></td>
                        </tr>
                        </table>
                    </div>                 
                  </div>
                  <div id="test-l-3" class="content ">                 
                    <table class="w-100 table-inline">
                      <tr>
                        <td style="width: 132px;">  New Password</td>
                        <td>    <input type="password" [(ngModel)]="NewPassword" class="form-control"  placeholder="xxxxxxxxx" /></td>
                      </tr>
                      <tr>
                        <td>Confirm Password</td>
                        <td> <input type="password" [(ngModel)]="CnfmNewPassword" class="form-control"  placeholder="xxxxxxxxx" /></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>  <button (click)="UpdateUserPassword()" class="btn btn-primary">Update Password</button></td>
                      </tr>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>