import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navabr',
  templateUrl: './navabr.component.html',
  styleUrls: ['./navabr.component.scss']
})
export class NavabrComponent implements OnInit {
  @Input() models: any;
  totallabresult:any=0;
  totalunreadresult:any=0;
  userdata:any
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.userdata=JSON.parse(localStorage.getItem("Loggedinuser"));
    this.models.subscribe(val=>{
      if(val!=undefined && val!=""){
        this.totallabresult=val.Totalcount;
        this.totalunreadresult=val.TotalunviewedCount
      }
    })
  }
  Logout(){
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }
  DoSomething(){
    
  }
}
