import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SrvDashboardService } from '../Services/srv-dashboard.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  //@Output() Resultcount: EventEmitter<any> = new EventEmitter<any>();
  TotalCount:string;
  TotalunviewedCount:string;
  Listtopten:any;
  ListUnread:any;
  anchor:any;
  status: boolean = true;
  status2: boolean = true;
  TreeData: BehaviorSubject<any> = new BehaviorSubject('');
  constructor(public objsrvdash:SrvDashboardService,private toastr: ToastrService,private router: Router) { }

  ngOnInit(): void {
    this.CheckLoginCredentials();
    
  }
  Close_tab(){
    this.status = !this.status;    
  }
  Close_tab2(){
    this.status2 = !this.status2;    
  }
  CheckLoginCredentials(){
    var userdata=JSON.parse(localStorage.getItem("Loggedinuser"));
    if(userdata == null || userdata ==""){
      this.router.navigateByUrl('/login');
    }
    else{
      this.GetDashbardData();
    }

  }
  GetDashbardData(){
    this.objsrvdash.GetDashboardDetails().subscribe(data=>{
      if(data.status){
        var resdata=data.ResultData;
        this.TotalCount=resdata.Table[0].TotalCount;
        this.TotalunviewedCount=resdata.Table1[0].TotalUnViewed;
        this.Listtopten=resdata.Table2;
        this.ListUnread=resdata.Table3;
        var obj={
          Totalcount:this.TotalCount,
          TotalunviewedCount:this.TotalunviewedCount
        }
        this.TreeData.next(obj);
      }
      else{
        this.toastr.error(data.ValidationMessage,'LabResultViewer');
      }
    })
  }
  ShowLabResult(list){
    debugger
    this.objsrvdash.GetFileDetails(list).subscribe(data=>{
      if(data.status){
        var resdata=data.ResultData;
        var datalist=resdata.Table[0];
        var binarydata =datalist.LabreportData;
        const byteCharacters = atob(binarydata);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/pdf'});
        var fileURL = webkitURL.createObjectURL(blob);
        this.GetDashbardData();
        window.open(fileURL); 
      }
      else{
        this.toastr.error(data.ValidationMessage,'LabResultViewer');
      }
    })
  }
  Download(list){
    this.objsrvdash.GetFileDetails(list).subscribe(data=>{
      if(data.status){
        var resdata=data.ResultData;
        var datalist=resdata.Table[0];
        var binarydata =datalist.LabreportData;
        const byteCharacters = atob(binarydata);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/pdf'});
       // var fileURL = URL.createObjectURL(blob);
        this.anchor = document.createElement('a');
        this.anchor.download = "Labresult";
        // Use the URL object to create a temporary URL
        this.anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        this.anchor.click();
      }
      else{
        this.toastr.error(data.ValidationMessage,'LabResultViewer');
      }
    })
   
  }
  Print(list){
    this.objsrvdash.GetFileDetails(list).subscribe(data=>{
      if(data.status){
        var resdata=data.ResultData;
        var datalist=resdata.Table[0];
        var binarydata =datalist.LabreportData;
        const byteCharacters = atob(binarydata);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(blob);
        // this.GetDashbardData();
        // window.open(fileURL); 
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = fileURL;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      }
      else{
        this.toastr.error(data.ValidationMessage,'LabResultViewer');
      }
    })
  }
  Logout(){
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }
}
