import { Injectable } from '@angular/core';
import { CommonService } from './CommonService';

@Injectable({
  providedIn: 'root'
})
export class SrvloginService {

  constructor(public comm:CommonService) {

   }  
   VerifyOTP(phone,patientid,hospname,otp){
    var body={
      "Usr_PhoneNumber":phone,
      "Usr_PatId":patientid,
      "ClientKey":hospname,
      "SmsOTP":otp
    }
   return  this.comm.postCommonApi("Login/VerifyOTP",body)
   }
   SendOTP(phone,patientid,hospname){
    var body={
      "Usr_PhoneNumber":phone,
      "Usr_PatId":patientid,
      "ClientKey":hospname
    }
   return  this.comm.postCommonApi("Login/GenerateForgptPwdOTP",body)
   }
   UserAuthentication(data){
     var body={
       "Usr_PhoneNumber":data.CountryCode+data.Loginphone_usrid,
       "Usr_Pwd":data.Loginpwd,
       "Usr_PatId":data.Loginpatid,
       "ClientKey":data.HospitalName
     }
    return  this.comm.postCommonApi("Login/UserAuthentication",body)
   }
   UpdateuserPassword(phone,password,patientid,hospname){
    var body={
      "Usr_PhoneNumber":phone,
      "Usr_Pwd":password,
      "Usr_PatId":patientid,
      "ClientKey":hospname
    }
   return  this.comm.postCommonApi("Login/UserPasswordCreation",body)
   }
   Createuserpassword(data){
    var body={
      "Usr_PhoneNumber":data.Loginphone_usrid,
      "Usr_Pwd":data.Loginpwd,
      "Usr_PatId":data.Loginpatid,
      "ClientKey":data.HospitalName
    }
   return  this.comm.postCommonApi("Login/UserPasswordCreation",body)
   }
   GetRequestList(data){
    var body={
      "Pat_Id":data.Pat_Id,
      "Pat_Name":data.Pat_Name,
      "From_Date":data.From_Date,
      "To_Date":data.To_Date,
      "CusName":data.CusName,
      "usr_id":data.usr_id,
      "Requestid":data.Req_Id
    }
    return this.comm.postCommonApi("RequestForm/GetRequestFormList",body);
  }
}
