import { Injectable } from '@angular/core';
import { CommonService } from './CommonService';
@Injectable({
  providedIn: 'root'
})
export class SrvDashboardService {

  constructor(public comm:CommonService) { }

  Createbody(payload){
    var userdata=JSON.parse(localStorage.getItem("Loggedinuser"));
    var body={
      LoggedInInfo:userdata[0],
      Payload:payload
    }
    return body;
  }

  GetDashboardDetails(){
    var userdata=JSON.parse(localStorage.getItem("Loggedinuser"));
    var body={
      LoggedInInfo:userdata[0]
    }
    return  this.comm.postCommonApi("LabresultDashboard/GetLabresultData",body)
  }

  GetFileDetails(data){
    var payload={     
    "Le_No":data.Le_No
    }
    return  this.comm.postCommonApi("LabresultDashboard/GetDataById",this.Createbody(payload))
  }
}
